import React from 'react';
import PropTypes from 'prop-types';
// Utils
import chevronLeft from '../../../assets/img/pictos/chevron-left-solid.svg';
import configuration from '../../../assets/img/pictos/configuration_blanc.png';
import contratsBlanc from '../../../assets/img/pictos/contrats_blanc.png';
import equipements from '../../../assets/img/pictos/equipements.png';
import etablissements from '../../../assets/img/pictos/etablissement.png';
import etablissementsBlanc from '../../../assets/img/pictos/etablissements_blanc.png';
import etablissementsBlancSimple from '../../../assets/img/pictos/etablissement_blanc_simple.png';
import etablissementsRoundedBlue from '../../../assets/img/pictos/etablissementsRoundedBlue.png';
import home from '../../../assets/img/pictos/home_blanc.png';
import homeblue from '../../../assets/img/pictos/home_blue.png';
import settings from '../../../assets/img/pictos/settings.png';
import interventionsWhite from '../../../assets/img/pictos/interventions_blanc.png';
import trainings from '../../../assets/img/pictos/formation.png';
import inputs from '../../../assets/img/pictos/saisies.png';
import monRegistreBlanc from '../../../assets/img/pictos/mon_registre_blanc.png';
import journalBlanc from '../../../assets/img/pictos/journal_blanc.png';
import saisiesBlanc from '../../../assets/img/pictos/saisies_blanc.png';
import depotDocBlanc from '../../../assets/img/pictos/depot_doc_blanc.png';
import depotRapportBlanc from '../../../assets/img/pictos/depot_rapport_blanc.png';
import prestataires from '../../../assets/img/pictos/prestataires.png';
import prestatairesBlanc from '../../../assets/img/pictos/prestataires_blanc.png';
import calendrier from '../../../assets/img/pictos/calendrier.png';
import contrats from '../../../assets/img/pictos/contrats.png';
import users from '../../../assets/img/pictos/users.png';
import interventions from '../../../assets/img/pictos/interventions.png';
import contact from '../../../assets/img/pictos/contact.png';
import documentaire from '../../../assets/img/pictos/documentaire.png';
import synthese from '../../../assets/img/pictos/synthese.png';
import syntheseBlanc from '../../../assets/img/pictos/synthese_blanc.png';
import usersWhite from '../../../assets/img/pictos/users_blanc.png';
import ecarnetsmallwhite from '../../../assets/img/pictos/e-carnet_blanc.png';
import archives from '../../../assets/img/pictos/archives.png';
import book from '../../../assets/img/pictos/book.png';
import suivieTravaux from '../../../assets/img/pictos/suivie_travaux.png';
import reglementation from '../../../assets/img/pictos/reglementation.png';
import flamme from '../../../assets/img/pictos/flamme.png';
import enveloppe from '../../../assets/img/pictos/enveloppe.png';
import notificationwhite from '../../../assets/img/pictos/notificationwhite.png';
import multifileBlanc from '../../../assets/img/pictos/multifile.png';
import depotrapport from '../../../assets/img/pictos/depot_rapport.png';
import vehiculeblanc from '../../../assets/img/pictos/vehicule_1_blanc.png';
import vehiculebleu from '../../../assets/img/pictos/vehicule_1_bleu.png';
import assuranceBleu from '../../../assets/img/pictos/assurance_1_bleu.png';
import carteGriseBleu from '../../../assets/img/pictos/carte_grise_bleu.png';
import entretienBleu from '../../../assets/img/pictos/entretien_1_bleu.png';
import hayonBleu from '../../../assets/img/pictos/hayon_bleu.png';
import controlTechniqueBleu from '../../../assets/img/pictos/control_technique_bleu.png';
import entreprises from '../../../assets/img/pictos/entreprisesST.png';
import autorites from '../../../assets/img/pictos/autoritesST.png';
import organismeAgrees from '../../../assets/img/pictos/organismeAgreeST.png';

const availablePictos = {
  organismeAgrees,
  autorites,
  entreprises,
  chevronLeft,
  configuration,
  contratsBlanc,
  equipements,
  etablissements,
  home,
  homeblue,
  interventionsWhite,
  settings,
  trainings,
  inputs,
  users,
  usersWhite,
  ecarnetsmallwhite,
  etablissementsBlanc,
  etablissementsRoundedBlue,
  contact,
  documentaire,
  synthese,
  monRegistreBlanc,
  journalBlanc,
  saisiesBlanc,
  depotDocBlanc,
  depotRapportBlanc,
  prestataires,
  calendrier,
  syntheseBlanc,
  contrats,
  interventions,
  etablissementsBlancSimple,
  archives,
  book,
  suivieTravaux,
  reglementation,
  flamme,
  enveloppe,
  notificationwhite,
  multifileBlanc,
  depotrapport,
  vehiculeblanc,
  vehiculebleu,
  assuranceBleu,
  carteGriseBleu,
  controlTechniqueBleu,
  entretienBleu,
  hayonBleu,
  prestatairesBlanc,
};

function Picto({
  className, name, width, height,
}) {
  return (
    <img className={className} src={availablePictos[name]} alt="" width={width} height={height} />
  );
}

Picto.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

Picto.defaultProps = {
  className: '',
  width: '25',
  height: '25',
};

export default Picto;
